
.container{
    margin:1rem 4rem;
  }

.header{
    margin-top: 4rem;
    margin-bottom: 4rem;
    font-size: 2.5rem;
    font-weight: 700;
    color: #5415a5;
  }

.info {
    line-height: 3.5rem;
    margin: 4rem 6rem;
    font-size: 2rem;
    font-weight: 400;
    color: #555555;
  }

/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 100em) {
    .container{
        margin:1rem 2rem;
    }

    .header {
        margin:2rem 2rem;
        font-size:2rem;
    }

    .info{
        margin: 1rem 1rem;
        font-size: 1.5rem;
      }
}