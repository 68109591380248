.container{
  margin:1rem 4rem;
}
.header{
  margin-top: 4rem;
  margin-bottom: 4rem;
  font-size: 2.5rem;
  font-weight: 700;
  color: #5415a5;
}

.info {
  line-height: 3.5rem;
  margin: 4rem 6rem;
  font-size: 2rem;
  font-weight: 400;
  color: #555555;
}


.privacy {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.link{
  background-color: #5CE1E6;
  border-radius: 2rem;
  padding:1rem 2rem;
  margin:1rem 4rem;
  font-size: 2.5rem;
  font-weight:800;
  color:#5415a5;
  text-decoration: none;
}
.link:hover{
  background-color: #47b7b3;
}

.contactInfo {
  line-height: 3.5rem;
  font-size: 2rem;
  font-weight: 500;
  color:#888888;
  text-decoration: none;
}

.contactInfo:hover{
  color:#47b7b3
}

.contactLink{ 
  margin:2rem;
}


/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 100em) {

  .container{
    margin: 1rem 2rem;
  }

.header {
  margin: .5rem 3.5rem 2rem 3.5rem;
  font-size:2rem;
}

.privacy {
  margin:3rem 0 4rem 0;
}

.info{
  margin:1rem 2rem;
  font-size: 1.5rem;
}
.text{
  font-size: 1.5rem;
}
.link{
  padding:1rem 1.5rem;
  margin:0rem 1rem;
  font-size: 1.5rem;
}

.contactInfo{
  margin: 0rem 1rem;
  font-size: 1.4rem;
}

.contactLink{
  margin:0rem;
}

}