.container{
  align-items: center;
  margin-top:2rem;
  margin-bottom: 2rem;
}
.logo {
  width: 35%;
}

/**************************/
/* BELOW 100em            */
/**************************/
  
@media (max-width: 100em) {
  .container{
    margin: 2rem 0;
  }

  .logo {
    width:40%;
  }
}