.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.header {
}
.footer {
}
