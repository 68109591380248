.section {
    text-align: center;
    margin: 2rem 3rem;
  }
  
  .footer-logo {
    margin: 0 auto;
  }
  
  .small-logo {
    display: flex;
    margin: 1rem auto;
    height: 8rem;
    border-radius: 50%;
    /* box-shadow: 0rem -0.5rem 6rem 1.25rem #f2b932; */
  }
  
  .section p {
    color: #6d6d6d;
    font-size: 2rem;
    margin: 2rem 0 0 0;
  }
  
  .linkContainer{
    margin: 1rem 0 0 0;
  }

.link {
  margin-top: 2rem;
  font-size: 1.8rem;
  font-weight: 400;
  color:#888888;
  text-decoration: none;
}

.link:hover{
  color:#47b7b3
}
  

/**************************/
/* BELOW 100em            */
/**************************/
  
  @media (max-width: 100em) {

  .section{
    margin-top: 1rem;
  }

  .section p {
    font-size:1.5rem;
  }
  .link {
    font-size: 1.5rem;
  }

}